import { Injectable }  from '@angular/core';
import { StateService } from './state.service';
 
@Injectable({
    providedIn: 'root',
})
export class AppInitService {
 
    constructor(private stateService: StateService) {
    }
    
    init() {
        return this.stateService.init();
    }
}
 