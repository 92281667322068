import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, UrlTree, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { StateService } from "../service";

@Injectable({
    providedIn: 'root',
})
export class AdminGuard  {

    constructor(private stateService: StateService,
        private router: Router) {

    }
    canActivate() {
        if (this.stateService.hasAdminRights()) {
            return true;
        } else {
            return this.router.parseUrl('/');
        }
    }
    canActivateChild() {
        return this.canActivate();
    }
}