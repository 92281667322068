<div class="container-fluid" *ngIf="rosters; else loading">
    <div class="row mt-2">
        <div class="col-12">
            <a href="javascript:void(0)" [routerLink]="['/']" class="float-end">Home</a>
        </div>
    </div>
    <div class="row mt-2">
        <ng-container *ngIf="stateService.hasAdminRights(); else noAdminRights">
            <div class="col-12" *ngFor="let roster of rosters">
                <div class="card mb-5  bg-secondary">
                    <div class="card-header text-white fs-4" >{{roster.owner.name}} <span class="float-end">{{roster.owner.teamName}}</span></div>
                    <div class="card-body">
                        <ng-container *ngIf="stateService.league.sport === 'BASEBALL'; else footballTable">
                            <table class="table table-striped table-sm" style="font-size:0.8em;">
                                <thead>
                                    <tr>
                                        <th>Pos</th>
                                        <th>Name</th>
                                        <th>Pos</th>
                                        <th>Own $</th>
                                        <th>Proj $</th>
                                        <th>Own &Delta;</th>
                                        <th>Rank</th>
                                        <th>AB</th>
                                        <th>R</th>
                                        <th>HR</th>
                                        <th>RBI</th>
                                        <th>SB</th>
                                        <th>OBP</th>
                                        <th>AVG</th>
                                        <th>SLG</th>
                                        <th>OPS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let spot of getSpots(roster, 'HITTER')">
                                        <td>{{spot.positionAbbreviation}}</td>
                                        <td><b>{{spot.player?.name}}</b></td>
                                        <td>{{spot.player?.position}}</td>
                                        <td>{{spot.player?.owner ? spot.player.ownerPrice : spot.player?.keeperPrice}}</td>
                                        <td>{{spot.player?.projectedDollar}}</td>
                                        <td *ngIf="spot.player?.owner || spot.player?.keeperOwner; else noOwner">{{spot.player?.projectedDollar - (spot.player?.owner ? spot.player.ownerPrice : spot.player?.keeperPrice)}}</td>
                                        <td>{{spot.player?.overallRank}}</td>
                                        <ng-template #noOwner>
                                            <td></td>
                                        </ng-template> 
                                        <ng-container *ngIf="!spot.player">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </ng-container>
                                        <ng-container *ngIf="spot.player">
                                            <td>{{spot.player.atBats}}</td>
                                            <td>{{spot.player.runs}}</td>
                                            <td>{{spot.player.homeRuns}}</td>
                                            <td>{{spot.player.runsBattedIn}}</td>
                                            <td>{{spot.player.stolenBases}}</td>
                                            <td>{{spot.player.onBasePercentage}}</td>
                                            <td>{{spot.player.average}}</td>
                                            <td>{{spot.player.sluggingPercentage}}</td>
                                            <td>{{spot.player.onBasePlusSluggingPercentage}}</td>
                                        </ng-container>
                                    </tr>
                                    <tr >
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getSpots(roster, 'HITTER').length}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getNumberOfPlayers(roster, 'HITTER')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;"></td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalOwnerPrice(roster, 'HITTER')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalProjectedDollar(roster, 'HITTER')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalProjectedDollar(roster, 'HITTER') - getTotalOwnerPrice(roster, 'HITTER')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;"></td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalAtBats(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalRuns(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalHomeRuns(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalRBIs(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalStolenBases(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalOBP(roster) | number : '1.3'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalAverage(roster) | number : '1.3'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalSlugging(roster) | number : '1.3'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalOPS(roster) | number : '1.3'}}</td>
                                    </tr>
                                </tbody>
                            </table>
        
                            <table class="table table-striped table-sm" style="font-size:0.8em;">
                                <thead>
                                    <tr>
                                        <th>Pos</th>
                                        <th>Name</th>
                                        <th>Pos</th>
                                        <th>Own $</th>
                                        <th>Proj $</th>
                                        <th>Own &Delta;</th>
                                        <th>Rank</th>
                                        <th>IP</th>
                                        <th>ERA</th>
                                        <th>WHIP</th>
                                        <th>K</th>
                                        <th>QS</th>
                                        <th>SV+H</th>
                                        <th>GS</th>
                                        <th>SV</th>
                                        <th>HD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let spot of getSpots(roster, 'PITCHER')">
                                        <td>{{spot.positionAbbreviation}}</td>
                                        <td><b>{{spot.player?.name}}</b></td>
                                        <td>{{spot.player?.position}}</td>
                                        <td>{{spot.player?.owner ? spot.player.ownerPrice : spot.player?.keeperPrice}}</td>
                                        <td>{{spot.player?.projectedDollar}}</td>
                                        <td *ngIf="spot.player?.owner || spot.player?.keeperOwner; else noOwner">{{spot.player?.projectedDollar - (spot.player?.owner ? spot.player.ownerPrice : spot.player?.keeperPrice)}}</td>
                                        <td>{{spot.player?.overallRank}}</td>
                                        <ng-template #noOwner>
                                            <td></td>
                                        </ng-template> 
                                        <ng-container *ngIf="!spot.player">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </ng-container>
                                        <ng-container *ngIf="spot.player">
                                            <td>{{spot.player.inningsPitched}}</td>
                                            <td>{{spot.player.era}}</td>
                                            <td>{{spot.player.whip}}</td>
                                            <td>{{spot.player.strikeouts}}</td>
                                            <td>{{spot.player.qualityStarts}}</td>
                                            <td>{{spot.player.savesPlusHolds}}</td>
                                            <td>{{spot.player.gamesStarted}}</td>
                                            <td>{{spot.player.saves}}</td>
                                            <td>{{spot.player.holds}}</td>
                                        </ng-container>
                                    </tr>
                                    <tr >
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getSpots(roster, 'PITCHER').length}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getNumberOfPlayers(roster, 'PITCHER')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;"></td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalOwnerPrice(roster, 'PITCHER')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalProjectedDollar(roster, 'PITCHER')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalProjectedDollar(roster, 'PITCHER') - getTotalOwnerPrice(roster, 'PITCHER')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;"></td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalInningsPitched(roster) | number : '1.1'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalERA(roster) | number : '1.2'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalWHIP(roster) | number : '1.2'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalPitcherStrikeouts(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalQualityStarts(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalSavesPlusHolds(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalGamesStarted(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalSaves(roster) | number : '1.0'}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalPitcherHolds(roster) | number : '1.0'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                        <ng-template #footballTable>
                            <table class="table table-striped table-sm" style="font-size:0.8em;">
                                <thead>
                                    <tr>
                                        <th>Pos</th>
                                        <th>Name</th>
                                        <th>Pos</th>
                                        <th>Own $</th>
                                        <th>Proj $</th>
                                        <th>Own &Delta;</th>
                                        <th>Rank</th>
                                        <th>Fan<br>Pts</th>
                                        <th>Pass<br>Yds</th>
                                        <th>Pass<br>Tds</th>
                                        <th>Pass<br>Int</th>
                                        <th>Rush<br>Att</th>
                                        <th>Rush<br>Yds</th>
                                        <th>Rush<br>Tds</th>
                                        <th>Rec</th>
                                        <th>Rec<br>Yds</th>
                                        <th>Rec<br>Tds</th>
                                        <th>Fumbles</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let spot of roster.spots">
                                        <td>{{spot.positionAbbreviation}}</td>
                                        <td><b>{{spot.player?.name}}</b></td>
                                        <td>{{spot.player?.position}}</td>
                                        <td>{{spot.player?.owner ? spot.player.ownerPrice : spot.player?.keeperPrice}}</td>
                                        <td>{{spot.player?.projectedDollar}}</td>
                                        <td *ngIf="spot.player?.owner || spot.player?.keeperOwner; else noOwner">{{spot.player?.projectedDollar - (spot.player?.owner ? spot.player.ownerPrice : spot.player?.keeperPrice)}}</td>
                                        <ng-template #noOwner>
                                            <td></td>
                                        </ng-template> 
                                        <td>{{spot.player?.overallRank}}</td>
                                        <ng-container *ngIf="!spot.player">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </ng-container>
                                        <ng-container *ngIf="spot.player">
                                            <td>{{spot.player.fantasyPoints}}</td>
                                            <td>{{spot.player.passYards}}</td>
                                            <th>{{spot.player.passTouchdowns}}</th>
                                            <th>{{spot.player.type === 'OFFENSE' ? spot.player.interceptions : ''}}</th>
                                            <th>{{spot.player.rushAttempts}}</th>
                                            <th>{{spot.player.rushYards}}</th>
                                            <th>{{spot.player.rushTouchdowns}}</th>
                                            <th>{{spot.player.receptions}}</th>
                                            <th>{{spot.player.receiveYards}}</th>
                                            <th>{{spot.player.receiveTouchdowns}}</th>
                                            <th>{{spot.player.fumbles}}</th>
                                        </ng-container>
                                    </tr>
                                    <tr >
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getSpots(roster, null).length}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getNumberOfPlayers(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;"></td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalOwnerPrice(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalProjectedDollar(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalProjectedDollar(roster, null) - getTotalOwnerPrice(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;"></td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalFantasyPoints(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalPassYards(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalPassTouchdowns(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalInterceptions(roster, 'OFFENSE')}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalRushAttempts(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalRushYards(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalRushTouchdowns(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalReceptions(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalReceiveYards(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalReceiveTouchdowns(roster, null)}}</td>
                                        <td style="border-top-width: 2px !important; border-top-color: #000 !important;">{{getTotalFumbles(roster, null)}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noAdminRights>
            <div class="col-12 col-lg-3" *ngFor="let roster of rosters">
                <div class="card mb-4">
                    <div class="card-header"><b>{{roster.owner.name}}</b> <span class="float-end">{{roster.owner.teamName}}</span></div>
                    <div class="card-body">
                        <div class="container-fluid">
                            <div class="row" *ngFor="let spot of roster.spots">
                                <div class="col-3">{{spot.positionAbbreviation}}</div>
                                <div class="col-7">{{spot.player?.name}}</div>
                                <div class="col-2">{{spot.player?.ownerPrice ? spot.player?.ownerPrice : spot.player?.keeperPrice}}</div>
                            </div>
                            <div class="row border-top mt-2 pt-2" style="font-weight: 700;">
                                <div class="col-3">Total</div>
                                <div class="col-7">{{getNumberOfPlayers(roster)}}</div>
                                <div class="col-2">{{getTotalOwnerPrice(roster)}}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<ng-template #loading>
    <div class="d-flex justify-content-center mt-5">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
</ng-template>    
