import { Component, OnDestroy, OnInit } from "@angular/core";
import { FantasyOwner, FinancialSummary, ModelFilter, Player, PropertyFilter } from "../model";
import { FantasyOwnerService, StateService } from "../service";
import { FinancialSummaryService } from "../service/financial-summary.service";
import { PlayerService } from "../service/player.service";

@Component({
    selector: 'draft-all-component',
    templateUrl: 'draft.component.html'
})
export class DraftAllComponent implements OnInit, OnDestroy {
    numberOfLoads: number = 0;
    owners: FantasyOwner[];
    players: Player[];
    pageStart: number;
    financialSummaries: FinancialSummary[];
    
    constructor(
        private playerService: PlayerService,
        private ownerService: FantasyOwnerService,
        private financialSummaryService: FinancialSummaryService,
        public stateService: StateService

    ) {

    }
    
    ngOnInit(): void {
        this.loadFinancialSummaries();
        this.loadOwners();
    }

    loadFinancialSummaries() {
        this.financialSummaryService.searchAll().subscribe(
            financialSummaries => {
                this.financialSummaries = financialSummaries;
            }
        )
    }

    loadOwners() {
        const filter = new ModelFilter();
        filter.addFilter(PropertyFilter.stringEquals('leagueId', this.stateService.league ? this.stateService.league.id : null));
        this.ownerService.searchAll(undefined, filter).subscribe(
            owners => {
                this.owners = owners;
                this.loadPlayers();
            }
        )
    }

    loadPlayers() {
        const filter = new ModelFilter();
        filter.addFilter(PropertyFilter.stringEquals('leagueId', this.stateService.league ? this.stateService.league.id : null));
        filter.addFilter(PropertyFilter.booleanEquals('takenOnly', true));

        this.playerService.searchAll(undefined, filter, 'draftTime', 'desc').subscribe(
            page => {
                this.players = page;
                this.pageStart = 0;
            }
        );
    }

    ngOnDestroy(): void {
    }


    getOwner(ownerId: string): FantasyOwner {
        let result: FantasyOwner = null;
        for (const o of this.owners) {
            if (o.id === ownerId) {
                result = o;
                break;
            }
        }
        return result;
    }

}