import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import * as Components from './component';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StateService } from './service';
import { AppInitService } from './service/app-init.service';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

@NgModule({
  declarations: [
    AppComponent,
    Components.ListPlayersComponent,
    Components.DraftComponent,
    Components.DraftAllComponent,
    Components.RostersComponent,
    Components.HomeComponent,
    Components.StandingsComponent,
    Components.FootballStandingsComponent,
    Components.AnalysisComponent,
    Components.SyncComponent,
    Components.LoginComponent,
    Components.ImportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DataTablesModule,
    FormsModule,
    DropzoneModule,
    ModalModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true
  },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.init();
  }
}
