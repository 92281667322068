<div class="container-fluid" *ngIf="rosters; else loading">
    <div class="row mt-2">
        <div class="col-12">
            <a href="javascript:void(0)" [routerLink]="['/']" class="float-end">Home</a>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <table class="table table-striped table-sm table-bordered" style="font-size:0.8em;">
                <thead>
                    <tr>
                        <th>Owner</th>
                        <th>Fan<br>Pts</th>
                        <th>Players</th>
                        <th>Own $</th>
                        <th>Proj $</th>
                        <th>Own &Delta;</th>
                        <th>Pass<br>Yds</th>
                        <th>Pass<br>Tds</th>
                        <th>Pass<br>Int</th>
                        <th>Rush<br>Att</th>
                        <th>Rush<br>Yds</th>
                        <th>Rush<br>Tds</th>
                        <th>Rec</th>
                        <th>Rec<br>Yds</th>
                        <th>Rec<br>Tds</th>
                        <th>Fumbles</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let roster of getSortedRosters()">
                        <td>{{roster.owner.name}}</td>
                        <td>{{getTotalFantasyPoints(roster, null)}}</td>
                        <td>{{getNumberOfPlayers(roster, null)}}</td>
                        <td>{{getTotalOwnerPrice(roster, null)}}</td>
                        <td>{{getTotalProjectedDollar(roster, null)}}</td>
                        <td>{{getTotalProjectedDollar(roster, null) - getTotalOwnerPrice(roster, null)}}</td>
                        <td>{{getTotalPassYards(roster, null)}}</td>
                        <td>{{getTotalPassTouchdowns(roster, null)}}</td>
                        <td>{{getTotalInterceptions(roster, 'OFFENSE')}}</td>
                        <td>{{getTotalRushAttempts(roster, null)}}</td>
                        <td>{{getTotalRushYards(roster, null)}}</td>
                        <td>{{getTotalRushTouchdowns(roster, null)}}</td>
                        <td>{{getTotalReceptions(roster, null)}}</td>
                        <td>{{getTotalReceiveYards(roster, null)}}</td>
                        <td>{{getTotalReceiveTouchdowns(roster, null)}}</td>
                        <td>{{getTotalFumbles(roster, null)}}</td>
                    </tr>                                    
                </tbody>
            </table>
        </div>        
    </div>
</div>

<ng-template #loading>
    <div class="d-flex justify-content-center mt-5">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
</ng-template>    
