<div class="container-fluid" *ngIf="players; else loading">
    <div class="row mt-2">
        <div class="col">
            <a href="javascript:void(0)" [routerLink]="['/']" class="float-end">Home</a>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" [class.active]="positionFilter==='Players'" href="javascript:void(0)" (click)="setPositionFilter('Players')">Players</a>
                </li>
                <ng-container *ngIf="sport === 'BASEBALL'">
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='Hitters'" href="javascript:void(0)" (click)="setPositionFilter('Hitters')">Hitters</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='Pitchers'" href="javascript:void(0)" (click)="setPositionFilter('Pitchers')">Pitchers</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='C'" href="javascript:void(0)" (click)="setPositionFilter('C')">C</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='1B'" href="javascript:void(0)" (click)="setPositionFilter('1B')">1B</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='2B'" href="javascript:void(0)" (click)="setPositionFilter('2B')">2B</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='3B'" href="javascript:void(0)" (click)="setPositionFilter('3B')">3B</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='IF'" href="javascript:void(0)" (click)="setPositionFilter('IF')">IF</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='CI'" href="javascript:void(0)" (click)="setPositionFilter('CI')">CI</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='MI'" href="javascript:void(0)" (click)="setPositionFilter('MI')">MI</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='SS'" href="javascript:void(0)" (click)="setPositionFilter('SS')">SS</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='OF'" href="javascript:void(0)" (click)="setPositionFilter('OF')">OF</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='DH'" href="javascript:void(0)" (click)="setPositionFilter('DH')">DH</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='SP'" href="javascript:void(0)" (click)="setPositionFilter('SP')">SP</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='RP'" href="javascript:void(0)" (click)="setPositionFilter('RP')">RP</a>
                    </li>

                </ng-container>
                <ng-container *ngIf="sport === 'FOOTBALL'">
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='QB'" href="javascript:void(0)" (click)="setPositionFilter('QB')">QB</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='RB'" href="javascript:void(0)" (click)="setPositionFilter('RB')">RB</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='WR'" href="javascript:void(0)" (click)="setPositionFilter('WR')">WR</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='FLEX'" href="javascript:void(0)" (click)="setPositionFilter('FLEX')">RB/WR</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='TE'" href="javascript:void(0)" (click)="setPositionFilter('TE')">TE</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='K'" href="javascript:void(0)" (click)="setPositionFilter('K')">K</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="positionFilter==='DEF'" href="javascript:void(0)" (click)="setPositionFilter('DEF')">DEF</a>
                    </li>

                </ng-container>

            </ul>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-2">
            <div class="input-group">
                <input type="text" class="form-control form-control-sm" placeholder="Search" autofocus #globalSearchElement [(ngModel)]="globalSearch" (ngModelChange)="globalSearchChange($event)" (keyup.enter)="onGlobalSearchEnterKeyUp($event)">
                <button class="btn btn-outline-secondary btn-sm " type="button" (click)="onClear()">Clear</button>
            </div>
        </div>
        <div class="col-10">
            <button class="btn btn-sm me-4" [class.btn-primary]="showAvailableOnly" [class.btn-outline-secondary]="!showAvailableOnly" type="button" (click)="onAvailableButtonClick()">Available</button>
            <button class="btn btn-sm me-4" [class.btn-primary]="hideLowRankedPlayers" [class.btn-outline-secondary]="!hideLowRankedPlayers" type="button" (click)="onHideLowRankedPlayersButtonClick()">Hide Low Ranked</button>
            <button class="btn btn-sm me-2" [class.btn-success]="noteType === 'target'" [class.btn-outline-success]="noteType !== 'target'" type="button" (click)="onNoteTypeButtonClick('target')">Target</button>
            <button class="btn btn-sm me-2" [class.btn-danger]="noteType === 'avoid'" [class.btn-outline-danger]="noteType !== 'avoid'" type="button" (click)="onNoteTypeButtonClick('avoid')">Avoid</button>
            <button class="btn btn-sm me-4" [class.btn-warning]="noteType === 'nominate'" [class.btn-outline-warning]="noteType !== 'nominate'"type="button" (click)="onNoteTypeButtonClick('nominate')">Nominate</button>
            <button class="btn btn-sm me-2" [class.btn-primary]="keeperPhase" [class.btn-outline-secondary]="!keeperPhase" type="button" (click)="onKeeperPhaseButtonClick()">Keeper Phase</button>
            <button class="btn btn-sm me-2" *ngIf="keeperPhase" [class.btn-primary]="keeperSetup" [class.btn-outline-secondary]="!keeperSetup" type="button" (click)="onKeeperSetupButtonClick()">Keeper Setup</button>
            <button class="btn btn-sm" *ngIf="keeperPhase" [class.btn-primary]="showKeepersOnly" [class.btn-outline-secondary]="!showKeepersOnly" type="button" (click)="onKeepersButtonClick()">Keepers Only</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="table-sm table-responsive" *ngIf="dtOptions">
                <table datatable [dtOptions]="dtOptions" class="table table-striped table-hover table-sm" width="100%">
                </table>
            </div>
        </div>
    </div>
</div>


<ng-template #editPlayerModal>
    <div class="modal-header">
        <div class="modal-title" style="width:100%">
            <span class="h4 mb-0 me-2">{{player.name}}</span>
            <span class="h6 mb-0 me-2">Team: {{player.team}}</span>
            <span class="h6 mb-0 me-4">Pos: {{player.position}}</span>
            <span class="h6 mb-0 me-4">Proj: ${{player.projectedDollar ? player.projectedDollar : 0}}</span>
            <span class="h6 mb-0 me-4">Tier {{player.tier}}: ${{tierLowPrice ?  tierLowPrice : 0}} - ${{tierHighPrice ? tierHighPrice : 0}}</span>
            <span class="h6 mb-0 me-4" *ngIf="player.keeperOwner">Kept By: {{player.keeperOwner?.name}} ${{player.keeperPrice}}</span>
            <span class="h6 mb-0" *ngIf="player.owner">Owned By: {{player.owner?.name}} ${{player.ownerPrice}}</span>
        </div>
      
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="container-fluid">
            <div class="form-row" *ngIf="keeperPhase && keeperSetup">
                <div class="form-group col">
                    <label for="keeperOwnerId">Keeper Owner</label>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-3 mb-2" *ngFor="let owner of owners">
                                <button type="button" class="btn" style="height:100%;width:100%" [class.btn-primary]="player.keeperOwner?.id === owner.id" [class.btn-outline-secondary]="player.keeperOwner?.id !== owner.id" (click)="setKeeperOwner(player, owner)"><b>{{owner.name}}</b><br>{{owner.teamName}}</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="form-group col">
                    <label for="keeperPrice">Keeper Price</label>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-6"><input type="text" class="form-control mb-2" [(ngModel)]="player.keeperPrice" (ngModelChange)="setKeeperPrice(player, player.keeperPrice)" placeholder="Keeper Price"></div>
                            <div class="col-3"><button type="button" class="btn btn-outline-secondary" style="width:100%" (click)="onClearKeeperButtonClick(player)">Clear</button> </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-1 mb-2 pl-1 pr-1" *ngFor="let price of keeperPriceRange">
                                <button type="button" [class]="'btn btn-sm '+getAdditionalPriceButtonClass(player.keeperPrice, player.projectedDollar, price)" style="width:100%" (click)="setKeeperPrice(player, price)">{{price}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row" *ngIf="!keeperPhase || !keeperSetup">
                <div class="form-group col">
                    <div class="row g-0">
                        <div class="col-3 p-2"  *ngFor="let owner of owners">
                            <button type="button" class="btn w-100"  [class.btn-primary]="player.owner?.id === owner.id" [class.btn-outline-secondary]="player.owner !== owner" [class.btn-warning]="!ownedPlayers.get(owner.id) || ownedPlayers.get(owner.id).length == 0" (click)="setOwner(player, owner)"><b>{{owner.name}}</b></button>
                            <table class="table table-borderless table-sm" style="font-size: .7em;" *ngIf="ownedPlayers.get(owner.id) && ownedPlayers.get(owner.id).length > 0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tier</th>
                                        <th>$</th>
                                        <th>Name</th>
                                        <th>Bye</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of ownedPlayers.get(owner.id)" [class.table-success]="player.id === p.id" [class.table-info]="player.id !== p.id && player.tier === p.tier">
                                        <td>{{p.positionRank}}</td>
                                        <td>{{p.tier}}</td>
                                        <td>${{p.projectedDollar ? p.projectedDollar : 0}}</td>
                                        <td>{{p.name}}</td>
                                        <td>{{p.bye}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-3 p-2">
                            <table class="table table-borderless table-sm" style="font-size: .7em;">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tier</th>
                                        <th>$</th>
                                        <th>Name</th>
                                        <th>Bye</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of undraftedPlayers" [class.table-info]="player.tier === p.tier">
                                        <td>{{p.positionRank}}</td>
                                        <td>{{p.tier}}</td>
                                        <td>${{p.projectedDollar ? p.projectedDollar : 0}}</td>
                                        <td>{{p.name}}</td>
                                        <td>{{p.bye}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-3 p-2">
                            <table class="table table-borderless table-sm" style="font-size: .7em;">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tier</th>
                                        <th>$</th>
                                        <th>Name</th>
                                        <th>Bye</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of undraftedPlayers2" [class.table-info]="player.tier === p.tier">
                                        <td>{{p.positionRank}}</td>
                                        <td>{{p.tier}}</td>
                                        <td>${{p.projectedDollar ? p.projectedDollar : 0}}</td>
                                        <td>{{p.name}}</td>
                                        <td>{{p.bye}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="form-group col mt-2 pt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-3"><input type="text" class="form-control mb-2" [(ngModel)]="player.ownerPrice" (blur)="setOwnerPrice(player, player.ownerPrice)" placeholder="Owner Price"></div>
                            <div class="col-3" *ngIf="keeperPhase">
                                <button type="button" class="btn" [class.btn-primary]="player.kept" [class.btn-outline-secondary]="!player.kept" style="width:100%" (click)="onKeepButtonClick(player)">Keep</button> 
                            </div>
                            <div class="col-3">
                                <button type="button" class="btn btn-outline-secondary" style="width:100%" (click)="onClearDraftButtonClick(player)">Clear</button> 
                            </div>

                            <div class="col-3">
                                <button type="button" class="btn btn-outline-secondary" style="width:100%" (click)="modalRef.hide()">Close</button> 
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row" *ngIf="keeperPhase && player.keeperPrice">
                            <div class="col-2 mb-2 pl-1 pr-1" *ngFor="let price of ownerPriceRange">
                                <button type="button" [class]="'btn btn-sm '+getAdditionalPriceButtonClass(player.ownerPrice, player.projectedDollar, price)" style="width:100%" (click)="setOwnerPrice(player, price)">{{price}} / {{getDiscountedPrice(player.keeperPrice, price)}}</button>
                            </div>
                        </div>
                        <div class="row" *ngIf="!keeperPhase || !player.keeperPrice">
                            <div class="col-1 mb-2 pl-1 pr-1" *ngFor="let price of ownerPriceRange">
                                <button type="button" [class]="'btn btn-sm '+getAdditionalPriceButtonClass(player.ownerPrice, player.projectedDollar, price)" style="width:100%" (click)="setOwnerPrice(player, price)">{{price}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row" *ngIf="sport === 'BASEBALL' && player.type === 'hitter'">
            <div class="col-12">
                <table class="table table-bordered table-sm" style="font-size:0.8em;">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Pos Rank</th>
                            <th>ADP</th>
                            <th>$</th>
                            <th>AB</th>
                            <th>R</th>
                            <th>HR</th>
                            <th>RBI</th>
                            <th>SB</th>
                            <th>OBP</th>
                            <th>AVG</th>
                            <th>SLG</th>
                            <th>OPS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{asHitter(player).overallRank}}</td>
                            <td>{{asHitter(player).positionRank}}</td>
                            <td>{{asHitter(player).overallADP}}</td>
                            <td>{{asHitter(player).projectedDollar}}</td>
                            <td>{{asHitter(player).atBats}}</td>
                            <td>{{asHitter(player).runs}}</td>
                            <td>{{asHitter(player).homeRuns}}</td>
                            <td>{{asHitter(player).runsBattedIn}}</td>
                            <td>{{asHitter(player).stolenBases}}</td>
                            <td>{{asHitter(player).onBasePercentage}}</td>
                            <td>{{asHitter(player).average}}</td>
                            <td>{{asHitter(player).sluggingPercentage}}</td>
                            <td>{{asHitter(player).onBasePlusSluggingPercentage}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="sport === 'BASEBALL' && player.type === 'pitcher'">
            <div class="col-12">
                <table class="table table-bordered table-sm" style="font-size:0.8em;">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Pos Rank</th>
                            <th>ADP</th>
                            <th>$</th>
                            <th>IP</th>
                            <th>K</th>
                            <th>ERA</th>
                            <th>WHIP</th>
                            <ng-container *ngIf="asPitcher(player).gamesStarted > 0">
                                <th>GS</th>    
                                <th>W</th>    
                            </ng-container>
                            <th>QS</th>
                            <ng-container *ngIf="asPitcher(player).savesPlusHolds > 0">
                                <th>SV</th>
                                <th>HLD</th>
                            </ng-container>
                            <th>SV+H</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{asPitcher(player).overallRank}}</td>
                            <td>{{asPitcher(player).positionRank}}</td>
                            <td>{{asPitcher(player).overallADP}}</td>
                            <td>{{asPitcher(player).projectedDollar}}</td>
                            <td>{{asPitcher(player).inningsPitched}}</td>
                            <td>{{asPitcher(player).strikeouts}}</td>
                            <td>{{asPitcher(player).era}}</td>
                            <td>{{asPitcher(player).whip}}</td>
                            <ng-container *ngIf="asPitcher(player).gamesStarted > 0">
                                <td>{{asPitcher(player).gamesStarted}}</td>    
                                <td>{{asPitcher(player).wins}}</td>    
                            </ng-container>
                            <td>{{asPitcher(player).qualityStarts}}</td>
                            <ng-container *ngIf="asPitcher(player).savesPlusHolds > 0">
                                <td>{{asPitcher(player).saves}}</td>
                                <td>{{asPitcher(player).holds}}</td>
                            </ng-container>
                            <td>{{asPitcher(player).savesPlusHolds}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>        
        <div class="row mt-2 mb-2" *ngIf="player.note">
            <div class="col-12" style="font-size:0.8em;" [innerHTML]="player.note">
                
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn" [class.btn-success]="player.noteType==='target'" [class.btn-outline-success]="player.noteType !== 'target'" (click)="setNoteType(player, 'target')">
                Target
            </button>
            <button type="button" class="btn" [class.btn-danger]="player.noteType==='avoid'" [class.btn-outline-danger]="player.noteType !== 'avoid'" (click)="setNoteType(player, 'avoid')">
                Avoid
            </button>
            <button type="button" class="btn" [class.btn-warning]="player.noteType==='nominate'" [class.btn-outline-warning]="player.noteType !== 'nominate'" (click)="setNoteType(player, 'nominate')">
                Nominate
            </button>
        </div>

    </div>
  </ng-template>



<ng-template #loading>
    <div class="d-flex justify-content-center mt-5">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
</ng-template>    