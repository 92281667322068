import { Component, OnInit } from "@angular/core";
import { LeagueService, StateService } from "../service";
import { League } from "../model";
import { Router } from "@angular/router";

@Component({
    template: ''
})
export class LoginComponent implements OnInit {
    
    constructor(
        private stateService: StateService,
        private router: Router
    ) {

    }
    ngOnInit(): void {
        this.stateService.setAdmin(true);
        this.router.navigate(['/']);
    }



}