import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Position } from "../model";
import { CrudService } from "./crud.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root',
})
export class PositionService extends CrudService<Position> {
    
    constructor(http: HttpClient, router: Router, private stateService: StateService) {
        super(http, router);
    }

    protected getApiPrefix(parentId?: string): string {
        return "positions";
    }

    
   
    
}
