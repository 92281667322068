import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FinancialSummary } from "../model";
import { CrudService } from "./crud.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root',
})
export class FinancialSummaryService {

    constructor(private http: HttpClient,
        private stateService: StateService
    ) {
        
    }
    
    searchAll() : Observable<FinancialSummary[]> {
        const params = {
            leagueId: this.stateService.league ? this.stateService.league.id : null
        };
        const options = { params: new HttpParams({ fromObject: params })};
        
        return this.http.get<FinancialSummary[]>(CrudService.getApiUrl()+"financialsummaries", options);
    }
}