import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'analysis-component',
    templateUrl: 'analysis.component.html'
})
export class AnalysisComponent implements OnInit{
    messages: string[] = ['OVERBID','Time out!', 'Did you see what he did in spring training?', 'Last good outfielder in the draft.', 'I was just price enforcing', 'That\'s the steal of the draft!'];
    message: string;

    ngOnInit() {
        const random = Math.floor(Math.random() * this.messages.length);
        this.message = this.messages[random];
    }
}