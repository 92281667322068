import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FinancialSummary, Roster } from "../model";
import { CrudService } from "./crud.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root',
})
export class RosterService {

    constructor(private http: HttpClient,         
        private stateService: StateService
    ) {
        
    }
    
    getRosters() : Observable<Roster[]> {
        const params = {
            leagueId: this.stateService.league ? this.stateService.league.id : null
        };
        const options = { params: new HttpParams({ fromObject: params })};
        
        return this.http.get<Roster[]>(CrudService.getApiUrl()+"rosters", options);
    }

    getPrivateRosters() : Observable<Roster[]> {
        const params = {
            leagueId: this.stateService.league ? this.stateService.league.id : null
        };
        const options = { params: new HttpParams({ fromObject: params })};
        
        return this.http.get<Roster[]>(CrudService.getApiUrl()+"rosters-private", options);
    }
}