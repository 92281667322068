<div *ngIf="projectedStandings" class="container-fluid">
    <table class="table table-striped table-sm mt-4">
        <thead class="thead-dark">
            <tr>
                <th>Owner</th>
                <th>R</th>
                <th>HR</th>
                <th>RBI</th>
                <th>SB</th>
                <th>OBP</th>
                <th>IP</th>
                <th>K</th>
                <th>ERA</th>
                <th>WHIP</th>
                <th>QS</th>
                <th>SV+H</th>            
                <th>$</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let total of projectedStandings.totals">
                <td>{{total.ownerName}}</td>
                <td>{{total.r}}</td>
                <td>{{total.hr}}</td>
                <td>{{total.rbi}}</td>
                <td>{{total.sb}}</td>
                <td>{{total.obp}}</td>
                <td>{{total.ip}}</td>
                <td>{{total.k}}</td>
                <td>{{total.era}}</td>
                <td>{{total.whip}}</td>
                <td>{{total.qs}}</td>
                <td>{{total.svPlusHld}}</td>

                <td>{{total.dollar}}</td>
            </tr>
        </tbody>
        <tr><td colspan="13">&nbsp;</td></tr>
        <thead class="thead-dark">
            <tr>
                <th>Owner</th>
                <th>R</th>
                <th>HR</th>
                <th>RBI</th>
                <th>SB</th>
                <th>OBP</th>
                <th></th>
                <th>K</th>
                <th>ERA</th>
                <th>WHIP</th>
                <th>QS</th>
                <th>SV+H</th>            
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let standing of projectedStandings.standings">
                <td>{{standing.ownerName}}</td>
                <td>{{standing.r}}</td>
                <td>{{standing.hr}}</td>
                <td>{{standing.rbi}}</td>
                <td>{{standing.sb}}</td>
                <td>{{standing.obp}}</td>
                <td></td>
                <td>{{standing.k}}</td>
                <td>{{standing.era}}</td>
                <td>{{standing.whip}}</td>
                <td>{{standing.qs}}</td>
                <td>{{standing.svPlusHld}}</td>

                <td>{{standing.total}}</td>
            </tr>
        </tbody>
    </table>    
</div>