import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProjectedStandings } from "../model";
import { ProjectedStandingsService } from "../service/projected-standings.service";

@Component({
    selector: 'standings-component',
    templateUrl: 'standings.component.html'
})
export class StandingsComponent implements OnInit, OnDestroy {
    projectedStandings: ProjectedStandings;
    
    constructor(
        private projectedStandingsService: ProjectedStandingsService
    ) {

    }
    
    ngOnInit(): void {
        this.loadProjectedStandings();
    }

    loadProjectedStandings() {
        this.projectedStandingsService.searchAll().subscribe(
            projectedStandings => {
                this.projectedStandings = projectedStandings;
                //console.log(this.projectedStandings);
            }
        )
    }

    ngOnDestroy(): void {
    }

}