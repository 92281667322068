import { Component } from "@angular/core";
import { RostersComponent } from "./rosters.component";
import { RosterService, StateService } from "../service";
import { Roster, RosterSpot } from "../model";

@Component({
    selector: 'football-standings-component',
    templateUrl: 'football-standings.component.html'
})
export class FootballStandingsComponent extends RostersComponent {
    
    constructor(
        rosterService: RosterService,
        public stateService: StateService
    ) {
        super(rosterService, stateService);
    }

    getSortedRosters() : Roster[] {
        let result: Roster[] = this.rosters.sort((n1,n2) => {
            const fp1 = this.getTotalFantasyPoints(n1, null);
            const fp2 = this.getTotalFantasyPoints(n2, null);

            if (fp1 > fp2) {
                return -1;
            }
        
            if (fp1 < fp2) {
                return 1;
            }
        
            return 0;
        });
        return result;
    }

    isSpotIncludedInTotal(rosterSpot: RosterSpot) : boolean {
        let result = true;
        if (rosterSpot.positionAbbreviation === 'BN' || rosterSpot.positionAbbreviation === 'IR') {
            result = false;
        }
        return result;
    }
}