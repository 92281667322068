import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Model, ModelFilter, Page, ReadHttpError, ValidatedResponse } from '../model';
import { environment } from '../../environments/environment';

export abstract class CrudService<M extends Model> {

    //constructor(protected http: HttpClient, protected userSession: UserSession, protected router: Router) {
    constructor(protected http: HttpClient, protected router: Router) {
    }

    public static getApiUrl(): string {
        let result = environment.API_PROTOCOL;

        result = result + environment.API_HOSTNAME;

        if (environment.API_PORT != null && environment.API_PORT !== '') {
            result += ':' + environment.API_PORT;
        }
        result = result + environment.API_PATH;
        return result;
    }


    static assemblePath(parts: string[]) {
		// cleanup
		const cleaned = parts.map((part: string) => {
			if (part.startsWith("/")) {
				part = part.substring(1);
			}
			if (part.endsWith("/")) {
				part = part.substring(0, part.length - 1)
			}
			return part;
		});

		const result = cleaned.reduce((a, b) => {
			return a + "/" + b;
		});

		return result;
    }    

    public static toHttpHeaders(headers) {
        return Object.getOwnPropertyNames(headers)
            .reduce((p, key) => p.set(key, headers[key]), new HttpHeaders());
    }

    protected abstract getApiPrefix(parentId?: string): string;

    public create(model: M, context?: string): Observable<ValidatedResponse<M>> {
        const path = (context ? context + '/' : '') + this.getApiPrefix();
        return this.post(model, path);
    }

    /**
     * Allows more flexible posts to be made to the server (without getApiPrefix, context, etc)
     * @param model
     * @param path - path after the "/api/" part...
     */
    public post(model: M, path: string): Observable<ValidatedResponse<M>> {
        const headers = {};
        headers['content-type'] = 'application/json; charset=utf-8';
        const options = { headers: CrudService.toHttpHeaders(headers) };
        const body = JSON.stringify(model);
        const target = CrudService.assemblePath([CrudService.getApiUrl(), path]);
        return this.http.post<ValidatedResponse<M>>(target, body, options)
            .pipe(
                catchError(res => {
                    if (res.status === 400) { //validation error
                        return of(res);
                    } else if (res.status === 401) { //authentication error
                        return of(res);
                    }
                    return observableThrowError(new ReadHttpError('There was an unexpected error'));
                })
            );
    }

    public get(path: string): Observable<any> {
        return this.http.get<M>(CrudService.getApiUrl() + this.getApiPrefix() + '/' + path);
    }

    public read(id: string, context?: string): Observable<M> {
        return this.http.get<M>(CrudService.getApiUrl() + (context ? context + '/' : '') + this.getApiPrefix() + '/' + id);
    }

    public count(context?: string, filter?: ModelFilter, headers?: HttpHeaders): Observable<number> {

        const params = {};

        if (filter) {
            params['filter'] = JSON.stringify(filter);
        }

        const options = { params: new HttpParams({ fromObject: params }), headers: headers };
        return this.http.get<number>(CrudService.getApiUrl() + (context ? context + '/' : '') + this.getApiPrefix() + '/count', options);
    }

    // public search(page?: number,
    //     pageSize?: number,
    //     context?: string,
    //     filter?: ModelFilter,
    //     sort?: string,
    //     order?: string): Observable<Page<M>> {

    //     const params = {};
    //     if (page) {
    //         params['offset'] = String(page);
    //     }
    //     if (pageSize) {
    //         params['limit'] = String(pageSize);
    //     }
    //     if (filter) {
    //         let filterJSON = JSON.stringify(filter);
    //         params['filter'] = filterJSON;
    //     }
    //     if (sort) {
    //         params['sort'] = sort;
    //     }
    //     if (order) {
    //         params['order'] = order;
    //     }

    //     const options = { params: new HttpParams({ fromObject: params }) };
    //     return this.http.get<Page<M>>(CrudService.getApiUrl() + (context ? context + '/' : '') + this.getApiPrefix(), options);
    // }

    // public searchAll(
    //     context?: string,
    //     filter?: ModelFilter,
    //     sort?: string,
    //     order?: string): Observable<M[]> {

    //     const params = {};
    //     if (filter) {
    //         let filterJSON = JSON.stringify(filter);
    //         params['filter'] = filterJSON;
    //     }
    //     if (sort) {
    //         params['sort'] = sort;
    //     }
    //     if (order) {
    //         params['order'] = order;
    //     }

    //     const options = { params: new HttpParams({ fromObject: params }) };
    //     return this.http.get<M[]>(CrudService.getApiUrl() + (context ? context + '/' : '') + this.getApiPrefix() + '/searchall', options);
    // }

    public search(page?: number,
        pageSize?: number,
        context?: string,
        filter?: ModelFilter,
        sort?: string,
        order?: string): Observable<Page<M>> {

        const params: any = {};
        if (page) {
            params['offset'] = String(page);
        }
        if (pageSize) {
            params['limit'] = String(pageSize);
        }
        if (filter) {
            let filterJSON = JSON.stringify(filter);
            params['filter'] = filterJSON;
        }
        if (sort) {
            params['sort'] = sort;
        }
        if (order) {
            params['order'] = order;
        }

        const options = { params: new HttpParams({ fromObject: params }) };
        return this.http.get<Page<M>>(CrudService.getApiUrl() + (context ? context + '/' : '') + this.getApiPrefix(), options);
    }


    public searchAll(
        context?: string,
        filter?: ModelFilter,
        sort?: string,
        order?: string): Observable<M[]> {

        const params: any = {};
        if (filter) {
            let filterJSON = JSON.stringify(filter);
            params['filter'] = filterJSON;
        }
        if (sort) {
            params['sort'] = sort;
        }
        if (order) {
            params['order'] = order;
        }

        const options = { params: new HttpParams({ fromObject: params }) };
        return this.http.get<M[]>(CrudService.getApiUrl() + (context ? context + '/' : '') + this.getApiPrefix() + '/searchall', options);
    }


    public update(model: M, context?: string): Observable<ValidatedResponse<M>> {
        const headers: any = {};
        headers['content-type'] = 'application/json; charset=utf-8';
        const options = { headers: CrudService.toHttpHeaders(headers) };
        const body = JSON.stringify(model);
        return this.http.put<ValidatedResponse<M>>(CrudService.getApiUrl() + (context ? context + '/' : '') + this.getApiPrefix() + '/' + model.id, body, options)
            .pipe(
                catchError(res => {
                    if (res.status === 400) { //validation error
                        return of(res);
                    } else if (res.status === 401) { //authentication error
                        return of(res);
                    }
                    return observableThrowError(new ReadHttpError('There was an unexpected error'));
                })
            );
    }

    public delete(id: string, context?: string): Observable<M> {
        return this.http.delete<M>(CrudService.getApiUrl() + (context ? context + '/' : '') + this.getApiPrefix() + '/' + id);
    }

}
