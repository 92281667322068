import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Player, Position } from "../model";
import { CrudService } from "./crud.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root',
})
export class PlayerService extends CrudService<Player> {
    
    constructor(http: HttpClient, router: Router, private stateService: StateService) {
        super(http, router);
    }

    protected getApiPrefix(parentId?: string): string {
        return "players";
    }

    
    // public searchAll(
    //     context?: string,
    //     filter?: PlayerFilter,
    //     sort?: string,
    //     order?: string): Observable<Player[]> {

    //     return super.searchAll(context, filter, sort, order).pipe(
    //         map(
    //             (players: any[]) => {
    //                 if (players) {
    //                     for (let i = 0; i < players.length; i++) {
    //                         if (players[i].type === 'hitter') {
    //                             players[i] = Object.assign(new Hitter(), players[i]);
    //                         } else if (players[i].type === 'pitcher') {
    //                             players[i] = Object.assign(new Pitcher(), players[i]);
    //                         }
    //                     }
    //                 }
    //                 return players;
    //             }
    //         )
    //     );
    // }


    public processUpload(data: any[], columnMappings: Map<string, string>, position: Position) : Observable<number> {
        let body = {
            leagueId: this.stateService.league.id,
            data: data,
            columnMappings: columnMappings,
            position: position ? position.abbreviation : null
        };

        return this.http.post<number> (CrudService.assemblePath([CrudService.getApiUrl(), this.getApiPrefix(), '/processUpload']), body);

    }


    public deletePlayers() : Observable<number> {

        const params = {
            leagueId: this.stateService.league.id
        };



        const options = { params: new HttpParams({ fromObject: params })};
        return this.http.get<number> (CrudService.assemblePath([CrudService.getApiUrl(), this.getApiPrefix(), '/deletePlayers']), options );

    }
    
}
