import { Component, OnInit } from "@angular/core";
import { LeagueService, StateService } from "../service";
import { League } from "../model";

@Component({
    selector: 'home-component',
    templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit {
    leagues: League[];

    constructor(
        private leagueService: LeagueService,
        public stateService: StateService
    ) {

    }
    ngOnInit(): void {
        this.leagueService.searchAll().subscribe(
            leagues => {
                this.leagues = leagues;
            }
        );
    }

    selectLeague(league: League): void {
        this.stateService.league = league;
    }

    clearLeague(): void {
        this.stateService.league = null;
    }

    logout(): void {
        this.stateService.setAdmin(false);
    }


}