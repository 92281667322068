import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CrudService } from "./crud.service";
import { League } from "../model";

@Injectable({
    providedIn: 'root',
})
export class LeagueService extends CrudService<League> {
    
    constructor(http: HttpClient, router: Router) {
        super(http, router);
    }

    protected getApiPrefix(parentId?: string): string {
        return "leagues";
    }

    
    
}
