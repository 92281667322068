import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FinancialSummary, Roster } from "../model";
import { CrudService } from "./crud.service";
import { StateService } from "./state.service";

@Injectable({
    providedIn: 'root',
})
export class SyncService {

    constructor(private http: HttpClient,
        private stateService: StateService
        ) {
        
    }
    
    sync(leagueId: string) : Observable<void> {
        const params = {
            leagueId: this.stateService.league ? this.stateService.league.id : null
        };
        const options = { params: new HttpParams({ fromObject: params })};
        
        return this.http.get<void>(CrudService.getApiUrl()+"sync", options);
    }

    parseRosters(rostersHtml) : Observable<void> {
        
        const headers = {};
        headers['content-type'] = 'application/json; charset=utf-8';
        const options = { headers: CrudService.toHttpHeaders(headers) };

        const body = JSON.stringify({
            leagueId: this.stateService.league ? this.stateService.league.id : null,
            rostersHtml: rostersHtml
        });

        return this.http.post<void>(CrudService.getApiUrl()+"parserosters", body, options);
    }

}