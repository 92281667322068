import { PropertyFilter } from "./property-filter";
import { Papa } from 'ngx-papaparse';

export class ModelFilter {
    search: string;
    focusId: string;
    filters: PropertyFilter[] = [];

    addFilter(filter: PropertyFilter) {
        this.filters.push(filter);
    }

    clearFilters() {
        this.filters = [];
    }
    
    getFilterByKey(key: string): PropertyFilter {
        let result: PropertyFilter = null;
        for (const filter of this.filters) {
            if (filter.key === key) {
                result = filter;
                break;
            }
        }
        return result;
    }

    getFilterValueByKey(key: string): string {
        let result: string = null;

        let propertyFilter = this.getFilterByKey(key);
        if (propertyFilter) {
            result = propertyFilter.value;
        }
        
        return result;
    }

    getFilterValueAsBooleanByKey(key: string): Boolean {
        let result: Boolean = null;

        let stringResult = this.getFilterValueByKey(key);
        if (stringResult) {
            result = (stringResult == "true");
        }
        return result;
    }

    getFilterValueAsListByKey(key: string): string[] {
        let result: string[] = null;

        let stringResult = this.getFilterValueByKey(key);
        if (stringResult) {
            let papa = new Papa();
            let parseResult = papa.parse(stringResult, {});
            result = parseResult.data;
        }
        return result;
    }

    removeFilter(key: string) {
        //Remove any that match
        for (let i = 0; i < this.filters.length; i++) {
            if (this.filters[i].key === key) {
                this.filters.splice(i, 1);
            }
        }
    }


    replaceFilter(filter: PropertyFilter) {
        this.removeFilter(filter.key);
        this.addFilter(filter);
    }

}
