import { Component, OnInit, ViewChild } from "@angular/core";
import { DropzoneConfigInterface, DropzoneDirective } from "ngx-dropzone-wrapper";
import { Papa, ParseResult } from "ngx-papaparse";
import { PlayerService } from "../service/player.service";
import { PositionService, StateService } from "../service";
import { ModelFilter, Position, PropertyFilter } from "../model";

@Component({
    selector: 'import-component',
    templateUrl: 'import.component.html'
})
export class ImportComponent implements OnInit {
    @ViewChild(DropzoneDirective, { static: false }) directiveRef?: DropzoneDirective;
    
    panel: string = '';
    public columnMappings: Map<string, string>;
    public config: DropzoneConfigInterface;
    public headers: string[];
    public possibleColumnTypes: any[];
    private parseResult: ParseResult;
    public sampleData: string[][];

    public message: string;

    public positions: Position[];
    public selectedPosition: Position;

    constructor(private playerService: PlayerService,
        private papa: Papa,
        private stateService: StateService,
        private positionService: PositionService
    
    ) {

    }
    ngOnInit(): void {
        this.config = {
            url: '/#/import/headers',
            acceptedFiles: 'text/csv',
            clickable: true,
            autoReset: null,
            errorReset: null,
            cancelReset: null,
            autoProcessQueue: false,
        };

        this.initPositions();

        this.initPossibleColumnTypes();
        this.panel = 'upload';
    }

    initPositions() {

        let filter = new ModelFilter();
        filter.addFilter(PropertyFilter.stringEquals('sport', this.stateService.league.sport));

        this.positionService.searchAll(null, filter).subscribe(
            results => {
                this.positions = results;
            },
            error => {
                console.log(error);
            }

        );
    }

    initPossibleColumnTypes() {
        this.possibleColumnTypes = [];
        this.possibleColumnTypes.push({'key':'nameDashTeam','label':'Name - Team', 'search': ['Name - Team']});
        this.possibleColumnTypes.push({'key':'firstLast','label':'First Last', 'search': ['Player', 'Name']});
        this.possibleColumnTypes.push({'key':'last,first','label':'Last, First', 'search': ['Last, First', 'Last Name, First Name']});
        this.possibleColumnTypes.push({'key':'firstName','label':'First Name', 'search': ['First Name']});
        this.possibleColumnTypes.push({'key':'lastName','label':'Last Name', 'search': ['Last Name']});
        this.possibleColumnTypes.push({'key':'teamAbbrev','label':'Team Abbrev', 'search': ['Team', 'Team Abbrev']});
        this.possibleColumnTypes.push({'key':'teamName','label':'Team Name', 'search': ['Team Name']});
        this.possibleColumnTypes.push({'key':'position','label':'Position', 'search': null}); //hack for now
        this.possibleColumnTypes.push({'key':'positionDashTeam','label':'Position - Team', 'search': ['POS - Team']}); 
        this.possibleColumnTypes.push({'key':'positionAndRank','label':'Position and Rank', 'search': ['Pos', 'Position', 'Position and Rank']});
        this.possibleColumnTypes.push({'key':'bye','label':'Bye Week', 'search': ['bye']});
        this.possibleColumnTypes.push({'key':'versusADP','label':'vs. ADP', 'search': ['vs.']});
        this.possibleColumnTypes.push({'key':'overallRank','label':'Overall Rank', 'search': ['rk', 'rank']});
        this.possibleColumnTypes.push({'key':'positionRank','label':'Position Rank', 'search': ['pos rk', 'position rank']});
        this.possibleColumnTypes.push({'key':'tier','label':'Tier', 'search': ['tier']});
        this.possibleColumnTypes.push({'key':'overallADP','label':'Overall ADP', 'search': ['overall adp']});
        this.possibleColumnTypes.push({'key':'positionADP','label':'Position ADP', 'search': ['position adp']});
        this.possibleColumnTypes.push({'key':'$','label':'Projected $', 'search': ['projected dollar', 'projected $', '$', 'values']});
        this.possibleColumnTypes.push({'key':'fantasyPoints','label':'Fantasy Points', 'search': ['fantasy points', 'points', 'fpts']});
        this.possibleColumnTypes.push({'key':'note','label':'Note', 'search': ['note']});
        this.possibleColumnTypes.push({'key':'risk','label':'Risk', 'search': ['risk']});
        this.possibleColumnTypes.push({'key':'keeperPrice','label':'Keeper Price', 'search': ['keeper $', 'keeper price']});
        this.possibleColumnTypes.push({'key':'keeperOwner','label':'Keeper Owner', 'search': ['keeper owner']});
        this.possibleColumnTypes.push({'key':'owner','label':'Owner', 'search': null});
        this.possibleColumnTypes.push({'key':'historical$','label':'Historical $', 'search': ['historical']});

        if (this.stateService.league.sport === 'FOOTBALL') {
            this.possibleColumnTypes.push({'key':'passAttempts','label':'Passing Attempts', 'search': ['pass att']});
            this.possibleColumnTypes.push({'key':'passCompletions','label':'Passing Completions', 'search': ['pass cmp']});
            this.possibleColumnTypes.push({'key':'passYards','label':'Passing Yards', 'search': ['pass yds']});
            this.possibleColumnTypes.push({'key':'passTouchdowns','label':'Passing Touchdowns', 'search': ['pass tds']});
            this.possibleColumnTypes.push({'key':'passInterceptions','label':'Passing Interceptions', 'search': ['pass ints']});
            this.possibleColumnTypes.push({'key':'rushAttempts','label':'Rushing Attempts', 'search': ['rush att']});
            this.possibleColumnTypes.push({'key':'rushYards','label':'Rushing Yards', 'search': ['rush yds']});
            this.possibleColumnTypes.push({'key':'rushTouchdowns','label':'Rushing Touchdowns', 'search': ['rush tds']});
            this.possibleColumnTypes.push({'key':'fumbles','label':'Fumbles', 'search': ['fl']});
            this.possibleColumnTypes.push({'key':'receptions','label':'Receptions', 'search': ['rec']});
            this.possibleColumnTypes.push({'key':'receiveYards','label':'Receiving Yards', 'search': ['rec yds']});
            this.possibleColumnTypes.push({'key':'receiveTouchdowns','label':'Receiving Touchdowns', 'search': ['rec tds']});
            this.possibleColumnTypes.push({'key':'fieldGoals','label':'Field Goals', 'search': ['fg']});
            this.possibleColumnTypes.push({'key':'fieldGoalAttempts','label':'Field Goal Attempts', 'search': ['fga']});
            this.possibleColumnTypes.push({'key':'extraPoints','label':'Extra Points', 'search': ['xpt']});
            this.possibleColumnTypes.push({'key':'sacks','label':'Sacks', 'search': ['sack']});
            this.possibleColumnTypes.push({'key':'defenseInterceptions','label':'Defensive Interceptions', 'search': ['def int']});
            this.possibleColumnTypes.push({'key':'fumblesRecovered','label':'Funmles Recovered', 'search': ['fr']});
            this.possibleColumnTypes.push({'key':'fumblesForced','label':'Fumbles Forced', 'search': ['ff']});
            this.possibleColumnTypes.push({'key':'defenseTouchdowns','label':'Defensive Touchdowns', 'search': ['def td']});
            this.possibleColumnTypes.push({'key':'safeties','label':'Safeties', 'search': ['safety']});
            this.possibleColumnTypes.push({'key':'pointsAgainst','label':'Points Against', 'search': ['def pa']});
            this.possibleColumnTypes.push({'key':'yardsAgainst','label':'Yards Against', 'search': ['yds_agn']});
            
        }
        
        if (this.stateService.league.sport === 'BASEBALL') {
            this.possibleColumnTypes.push({'key':'selectedPosition','label':'Selected Position', 'search': ['selected position', 'selected pos']});
            this.possibleColumnTypes.push({'key':'bats','label':'Bats', 'search': ['bats']});
            this.possibleColumnTypes.push({'key':'games','label':'Games', 'search': ['g']});
            this.possibleColumnTypes.push({'key':'plateAppearances','label':'Plate Appearances', 'search': ['pa']});
            this.possibleColumnTypes.push({'key':'atBats','label':'At Bats', 'search': ['ab']});
            this.possibleColumnTypes.push({'key':'runs','label':'Runs', 'search': ['r']});
            this.possibleColumnTypes.push({'key':'homeRuns','label':'Home Runs', 'search': ['hr']});
            this.possibleColumnTypes.push({'key':'runsBattedIn','label':'RBI', 'search': ['rbi']});
            this.possibleColumnTypes.push({'key':'stolenBases','label':'Stolen Bases', 'search': ['sb']});
            this.possibleColumnTypes.push({'key':'hits','label':'Hits', 'search': ['h']});
            this.possibleColumnTypes.push({'key':'singles','label':'Singles', 'search': ['1b']});
            this.possibleColumnTypes.push({'key':'doubles','label':'Doubles', 'search': ['2b']});
            this.possibleColumnTypes.push({'key':'triples','label':'Triples', 'search': ['3b']});
            this.possibleColumnTypes.push({'key':'totalBases','label':'Total Bases', 'search': ['tb']});
            this.possibleColumnTypes.push({'key':'hittingStrikeouts','label':'Hitting Strikeouts', 'search': ['so']});
            this.possibleColumnTypes.push({'key':'hittingWalks','label':'Hitting Walks', 'search': ['bb']});
            this.possibleColumnTypes.push({'key':'hittingHitByPitches','label':'Hitting Hit By Pitch', 'search': ['hbp']});
            this.possibleColumnTypes.push({'key':'sacrificeFlies','label':'Sacrifice Flies', 'search': ['sf']});
            this.possibleColumnTypes.push({'key':'sacrificeHits','label':'Sacrifice Hits', 'search': ['sh']});
            this.possibleColumnTypes.push({'key':'caughtStealing','label':'Caught Stealing', 'search': ['cs']});
            this.possibleColumnTypes.push({'key':'average','label':'Average', 'search': ['avg']});
            this.possibleColumnTypes.push({'key':'onBasePercentage','label':'OBP', 'search': ['obp']});
            this.possibleColumnTypes.push({'key':'sluggingPercentage','label':'Slugging', 'search': ['slg']});
            this.possibleColumnTypes.push({'key':'onBasePlusSlugging','label':'OPS', 'search': ['ops']});
            // this.possibleColumnTypes.push({'key':'$R','label':'', 'search': ['$R']});
            // this.possibleColumnTypes.push({'key':'$RBI','label':'', 'search': ['$RBI']});
            // this.possibleColumnTypes.push({'key':'$HR','label':'', 'search': ['$HR']});
            // this.possibleColumnTypes.push({'key':'$SB','label':'', 'search': ['$SB']});
            // this.possibleColumnTypes.push({'key':'$OBP','label':'', 'search': ['$OBP']});
            this.possibleColumnTypes.push({'key':'throws','label':'Throws', 'search': ['throws']});
            this.possibleColumnTypes.push({'key':'gamesPitched','label':'Games Pitched', 'search': ['g']});
            this.possibleColumnTypes.push({'key':'gamesStarted','label':'Games Started', 'search': ['gs']});
            this.possibleColumnTypes.push({'key':'qualityStarts','label':'Quality Starts', 'search': ['qs']});
            this.possibleColumnTypes.push({'key':'totalBattersFaced','label':'Total Batters Faced', 'search': ['tbf']});
            this.possibleColumnTypes.push({'key':'inningsPitched','label':'Innings Pitched', 'search': ['ip']});
            this.possibleColumnTypes.push({'key':'wins','label':'Wings', 'search': ['w']});
            this.possibleColumnTypes.push({'key':'losses','label':'Losses', 'search': ['l']});
            this.possibleColumnTypes.push({'key':'saves','label':'Saves', 'search': ['sv']});
            this.possibleColumnTypes.push({'key':'holds','label':'Holds', 'search': ['hld']});
            this.possibleColumnTypes.push({'key':'savesPlusHolds','label':'Saves Plus Holds', 'search': ['sv_plus_hld']});
            this.possibleColumnTypes.push({'key':'earnedRunAverage','label':'ERA', 'search': ['era']});
            this.possibleColumnTypes.push({'key':'siera','label':'SIERA', 'search': ['siera']});
            this.possibleColumnTypes.push({'key':'whip','label':'WHIP', 'search': ['whip']});
            this.possibleColumnTypes.push({'key':'pitchingStrikeouts','label':'Pitching Strikeouts', 'search': ['k']});
            this.possibleColumnTypes.push({'key':'walksAllowed','label':'Walks Allowed', 'search': ['bb']});
            this.possibleColumnTypes.push({'key':'hitsAllowed','label':'Hits Allowed', 'search': ['h']});
            this.possibleColumnTypes.push({'key':'pitchingHitByPitches','label':'Pitching Hit by Pitch', 'search': ['hbp']});
            this.possibleColumnTypes.push({'key':'earnedRuns','label':'Earned Runs', 'search': ['er']});
            this.possibleColumnTypes.push({'key':'runsAllowed','label':'Runs Allowed', 'search': ['r']});
            this.possibleColumnTypes.push({'key':'homeRunsAllowed','label':'Home Runs Allowed', 'search': ['hr']});
            this.possibleColumnTypes.push({'key':'groundBallPercentage','label':'Ground Ball %', 'search': ['gb%']});
            this.possibleColumnTypes.push({'key':'flyBallPercentage','label':'Fly Ball %', 'search': ['fb%']});
            this.possibleColumnTypes.push({'key':'lineDrivePercentage','label':'Line Drive %', 'search': ['ld%']});
            this.possibleColumnTypes.push({'key':'babip','label':'BABIP', 'search': ['babip']});
            // this.possibleColumnTypes.push({'key':'$ERA','label':'', 'search': ['$ERA']});
            // this.possibleColumnTypes.push({'key':'$WHIP','label':'', 'search': ['$WHIP']});
            // this.possibleColumnTypes.push({'key':'$QS','label':'', 'search': ['$QS']});
            // this.possibleColumnTypes.push({'key':'$K','label':'', 'search': ['$K']});
            // this.possibleColumnTypes.push({'key':'$SV_PLUS_HLDS','label':'', 'search': ['$SV_PLUS_HLDS']});



        }

    }

    public readFile() {
        const file: File = this.directiveRef.dropzone().files[0];
        if (file) {
            const fileReader = new FileReader();
            let that = this;
            fileReader.onloadend = (e) => {
                const rows = fileReader.result as string;
                let options = { header: true };
                this.parseResult =  this.papa.parse(rows, options);
                this.headers = that.parseResult.meta.fields;
                that.initColumnMappings();
                that.initSampleData();
                that.panel = 'headers';

            };
            fileReader.readAsText(file);
        } else {
            this.message = "You must first choose a file";
            this.panel = 'error';
        }
    }

    initSampleData() {
        var i = 0;
        this.sampleData = [];
        for (let item of this.parseResult.data) {
            let row : string[] = [];
            for (let header of this.headers) {
                row.push(item[header]);
            }
            this.sampleData.push(row);
            i++;
            if (i > 3) {
                break;
            }
        }
        //console.log("sampleData", this.sampleData);
    }

    initColumnMappings() {
        this.columnMappings = new Map<string, string>();

        //console.log("initColumnMappings");
        for (let header of this.headers) {
            let colType = this.findColumnType(header);
            if (colType) {
                this.columnMappings[header] = colType.key;
            }

        }
    }

    private findColumnType(header: string): any {
        let result:any = null;
        //console.log("findColumnType", header);
        if (this.possibleColumnTypes) {
            for (let colType of this.possibleColumnTypes) {
                //console.log("colType", colType);
                if (colType.search) {
                    for (var search of colType.search) {
                        search = search.toLowerCase();
                        //console.log("search", search);
                        if (header.toLowerCase().indexOf(search) > -1) {
                            result = colType;
                            //console.log("found", result);
                            break;
                        }
                    }
                }
            }
        }

        return result;
    }
    public process() {

        //console.log("columnMappings", this.columnMappings);
        if (!this.hasPositionColumn() && !this.selectedPosition) {
            this.message = 'You must choose a position';
            this.panel = 'error';
        } else {
            this.playerService.processUpload(this.parseResult.data, this.columnMappings, this.selectedPosition).subscribe(
                result => {
                    console.log("result", result);
                    this.message = result + " player(s) updated.";
                    this.panel = 'success';
    
                },
                error => {
                    console.log("error", error);
                    this.message = "There was an error: "+JSON.stringify(error);
                    this.panel = 'error';
                }
    
            );
        }

        
        
    }

    onUploadInit(event: any) {

    }

    public onUploadError(args: any): void {
        //console.log('onUploadError:', args);
    }

    onUploadSuccess(event: any) {
        //console.log("onUploadSuccess", event);
    }

    onUploadCanceled(event: any) {
        //console.log("canceled");
    }

    reset() {
        this.parseResult = null;
        this.columnMappings = null;
        this.sampleData = null;
        this.headers = null;
        this.selectedPosition = null;
        this.panel = 'upload';
    }

    deletePlayers() {
        this.playerService.deletePlayers().subscribe(
            result => {
                console.log(result + " players deleted")
            }, 
            error => {
                console.log(error);
            }
        );
    }

    hasPositionColumn() : boolean {
        let result = false;
        if (this.columnMappings) {
            for (let header of this.headers) {
                let colType = this.columnMappings[header];
                if (colType && colType.indexOf("position") > -1) {
                    result = true;
                    break;
                }
    
            }
    
        }
        return result;
    }

    
}