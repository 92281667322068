import { Injectable } from "@angular/core";
import { League } from "../model";
import { LeagueService } from "./league.service";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private static LEAGUE_ID = "ud_state_league_id";
    private static ADMIN = "ud_state_admin";

    private _league: League;
    private _admin: boolean = false;

    constructor(
        private leagueService: LeagueService
    ) {
    }

    init() {
        return new Promise<League> (
            (resolve, reject) => {
            let a = localStorage.getItem(StateService.ADMIN);
            if (a === 'true') {
                this._admin = true;
            } else {
                this._admin = false;
            }

            let leagueId = localStorage.getItem(StateService.LEAGUE_ID);
            if (leagueId) {
                this.leagueService.get(leagueId).subscribe(
                    league => {
                        this.league = league;
                        resolve(this.league);
                    },
                    error => {
                        console.log("error");
                        this.league = null;
                        reject();
                    }
                )
            } else {
                resolve(null);
            }
        });
    }

    get league() {
        return this._league;
    }

    set league(newLeague: League) {
        this._league = newLeague;

        if (this._league) {
            localStorage.setItem(StateService.LEAGUE_ID, this._league.id);
        } else {
            localStorage.removeItem(StateService.LEAGUE_ID);
        }

    }

    hasAdminRights(): boolean {
        return this._admin;
    }

    setAdmin(newAdmin: boolean) {
        this._admin = newAdmin;

        if (this._admin) {
            localStorage.setItem(StateService.ADMIN, 'true');
        } else {
            localStorage.removeItem(StateService.ADMIN);
        }
    }

   


}