import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Player } from "../model";

@Injectable({
    providedIn: 'root',
})
export class HeadshotService {
    private players: any[];

    constructor(private http: HttpClient) {
        // this.http.get<any>("http://lookup-service-prod.mlb.com/json/named.search_player_all.bam?sport_code=%27mlb%27&active_sw=%27Y%27&name_part=%27%25%27").subscribe(
        //     result => {
        //         this.players = result['search_player_all']['queryResults']['row'];
        //     });
    }

    getPlayerId(player: Player) : string {
        let result: string = '';
        if (this.players) {
            for (let p of this.players) {
                if (p['name_display_first_last'] === player.name && p['team_abbrev'] === player.team){
                    result = p['player_id'];
                    break;
                }
            }
        }
        return result;
    }

}