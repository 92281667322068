import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Hitter, Pitcher, Player, Roster, RosterSpot } from "../model";
import { RosterService, StateService } from "../service";
import { Client, StompSubscription } from '@stomp/stompjs';
import { UberdraftUtil } from "../util/uberdraft.util";

@Component({
    selector: 'rosters-component',
    templateUrl: 'rosters.component.html'
})
export class RostersComponent implements OnInit, OnDestroy {
    rosters: Roster[];

    client: Client;
    subscription: StompSubscription;

    constructor(
        public rosterService: RosterService,
        public stateService: StateService
    ) {

    }
    
    ngOnInit(): void {
        this.loadRosters();

        this.initializeWebSocketConnection();
    }

    loadRosters() {
        if (this.stateService.hasAdminRights()) {
            this.rosterService.getPrivateRosters().subscribe(
                rosters => {
                    this.rosters = rosters;
                }
            );
        } else {
            this.rosterService.getRosters().subscribe(
                rosters => {
                    this.rosters = rosters;
                    //console.log("rosters", rosters);
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.client.deactivate();

    }


    initializeWebSocketConnection() {
        this.client = new Client({
            brokerURL: environment.WS_ENDPOINT,
            debug: function (str) {
              //console.log(str);
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
          });
          
          let that = this;
          this.client.onConnect = function (frame) {
            // Do something, all subscribes must be done is this callback
            // This is needed because this will be executed after a (re)connect
                that.subscription = that.client.subscribe('/topic/players', function (message) {
                    // called when the client receives a STOMP message from the server
                    if (message.body) {
                        //console.log('got message with body ' + message.body);
                    } else {
                        //console.log('got empty message');
                    }
                    that.loadRosters();
                }
            );

          };
          
          this.client.onStompError = function (frame) {
            // Will be invoked in case of error encountered at Broker
            // Bad login/passcode typically will cause an error
            // Complaint brokers will set `message` header with a brief message. Body may contain details.
            // Compliant brokers will terminate the connection after any error
            console.log('Broker reported error: ' + frame.headers['message']);
            console.log('Additional details: ' + frame.body);
          };
          
          this.client.activate();
      }

      isInCategory(rosterSpot: RosterSpot, category: string) : boolean {
        let result = false;
        if (rosterSpot.player) {
            result = rosterSpot.player.type.toUpperCase() === category;
        } else {
            result = rosterSpot.positionCategory.toUpperCase() === category;
        }
        
        return result;
    }

    getNumberOfPlayers(roster: Roster, category?: string) : number {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player) {
                        result++;
                    }
                }
            }
        }
        return result;
    }

    isSpotIncludedInTotal(rosterSpot: RosterSpot) : boolean {
        let result = true;
        return result;
    }

  
    getTotalOwnerPrice(roster: Roster, category?: string) : number {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player) {
                        if (rs.player.ownerPrice) {
                            result += (rs.player as Player).ownerPrice;
                        } else if (rs.player.keeperPrice) {
                            result += (rs.player as Player).keeperPrice;
                        }
                    }
                }
            }
        }
        return result;
    }

    getTotalProjectedDollar(roster: Roster, category?: string): number {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player) {
                        result += (rs.player as Player).projectedDollar;
                    }
                }
            }
        }
        return result;
    }

    getTotalInningsPitched(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        result += (rs.player as Pitcher).inningsPitched;
                    }
                }
            }
        }
        return result;
    }

    getTotalERA(roster: Roster) {
        let result = 0;
        if (roster) {
            let totalInningsPitched = 0;
            let totalEarnedRuns = 0;
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        let inningsPitched = (rs.player as Pitcher).inningsPitched;
                        let earnedRuns = (rs.player as Pitcher).era * inningsPitched / 9;
                        totalInningsPitched += inningsPitched;
                        totalEarnedRuns += earnedRuns;
                    }
                }
            }
            result = totalEarnedRuns * 9 / totalInningsPitched;
        }
        return result;
    }

    getTotalWHIP(roster: Roster) {
        let result = 0;
        if (roster) {
            let totalInningsPitched = 0;
            let totalWalksAndHits = 0;
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        let inningsPitched = (rs.player as Pitcher).inningsPitched;
                        let walksAndHits = (rs.player as Pitcher).whip * inningsPitched;
                        totalInningsPitched += inningsPitched;
                        totalWalksAndHits += walksAndHits;
                    }
                }
            }
            result = totalWalksAndHits / totalInningsPitched;
        }
        return result;
    }

    getTotalPitcherStrikeouts(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        result += (rs.player as Pitcher).strikeouts;
                    }
                }
            }
        }
        return result;
    }

    getTotalGamesStarted(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        result += (rs.player as Pitcher).gamesStarted;
                    }
                }
            }
        }
        return result;
    }
    getTotalPitcherHolds(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        result += (rs.player as Pitcher).holds;
                    }
                }
            }
        }
        return result;
    }
    getTotalSaves(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        result += (rs.player as Pitcher).saves;
                    }
                }
            }
        }
        return result;
    }
    getTotalSavesPlusHolds(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        result += (rs.player as Pitcher).savesPlusHolds;
                    }
                }
            }
        }
        return result;
    }
    getTotalQualityStarts(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'PITCHER')) {
                    if (rs.player) {
                        result += (rs.player as Pitcher).qualityStarts;
                    }
                }
            }
        }
        return result;
    }

    getTotalAtBats(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        result += (rs.player as Hitter).atBats;
                    }
                }
            }
        }
        return result;
    }

    getTotalRuns(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        result += (rs.player as Hitter).runs;
                    }
                }
            }
        }
        return result;
    }

    getTotalHomeRuns(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        result += (rs.player as Hitter).homeRuns;
                    }
                }
            }
        }
        return result;
    }

    getTotalRBIs(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        result += (rs.player as Hitter).runsBattedIn;
                    }
                }
            }
        }
        return result;
    }

    getTotalStolenBases(roster: Roster) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        result += (rs.player as Hitter).stolenBases;
                    }
                }
            }
        }
        return result;
    }

    getTotalOBP(roster: Roster) {
        let result = 0;
        if (roster) {
            let totalAtBats = 0;
            let totalHits = 0;
            let totalWalks = 0;
            let totalHitByPitch = 0;
            let totalSacrificeFlies = 0;
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        totalAtBats += (rs.player as Hitter).atBats;
                        totalHits += (rs.player as Hitter).hits;
                        totalWalks += (rs.player as Hitter).walks;
                        totalHitByPitch += (rs.player as Hitter).hitByPitches;
                        totalSacrificeFlies += (rs.player as Hitter).sacrificeFlies;
                    }
                }
            }
            result = (totalHits + totalWalks + totalHitByPitch) / (totalAtBats + totalWalks + totalHitByPitch + totalSacrificeFlies);
        }
        return result;
    }

    getTotalAverage(roster: Roster) {
        let result = 0;
        if (roster) {
            let totalAtBats = 0;
            let totalHits = 0;
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        totalAtBats += (rs.player as Hitter).atBats;
                        totalHits += (rs.player as Hitter).hits;
                    }
                }
            }
            result = totalHits / totalAtBats;
        }
        return result;
    }

    getTotalSlugging(roster: Roster) {
        let result = 0;
        if (roster) {
            let totalAtBats = 0;
            let totalHits = 0;
            let totalWalks = 0;
            let totalHitByPitch = 0;
            let totalSacrificeFlies = 0;
            let totalTotalBases = 0;
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        totalAtBats += (rs.player as Hitter).atBats;
                        totalHits += (rs.player as Hitter).hits;
                        totalWalks += (rs.player as Hitter).walks;
                        totalHitByPitch += (rs.player as Hitter).hitByPitches;
                        totalSacrificeFlies += (rs.player as Hitter).sacrificeFlies;
                        totalTotalBases += (rs.player as Hitter).totalBases;
                    }
                }
            }
            result = totalTotalBases / totalAtBats;
        }
        return result;
    }

    getTotalOPS(roster: Roster) {
        let result = 0;
        if (roster) {
            let totalAtBats = 0;
            let totalHits = 0;
            let totalWalks = 0;
            let totalHitByPitch = 0;
            let totalSacrificeFlies = 0;
            let totalTotalBases = 0;
            for (let rs of roster.spots) {
                if (this.isInCategory(rs, 'HITTER')) {
                    if (rs.player) {
                        totalAtBats += (rs.player as Hitter).atBats;
                        totalHits += (rs.player as Hitter).hits;
                        totalWalks += (rs.player as Hitter).walks;
                        totalHitByPitch += (rs.player as Hitter).hitByPitches;
                        totalSacrificeFlies += (rs.player as Hitter).sacrificeFlies;
                        totalTotalBases += (rs.player as Hitter).totalBases;
                    }
                }
            }
            result = (totalHits + totalWalks + totalHitByPitch) / (totalAtBats + totalWalks + totalHitByPitch + totalSacrificeFlies) + totalTotalBases / totalAtBats;
        }
        return result;
    }

    
    

    getSpots(roster: Roster, category?: string) : RosterSpot[] {
        let result : RosterSpot[] = [];
        for (let rs of roster.spots) {
            if (!category || this.isInCategory(rs, category)) {
                result.push(rs);
            }
        }
        return result;
    }

    getTotalFantasyPoints(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.fantasyPoints) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += (rs.player as Player).fantasyPoints;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalPassYards(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.passYards) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.passYards;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalPassTouchdowns(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.passTouchdowns) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.passTouchdowns;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalInterceptions(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.interceptions) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.interceptions;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalRushAttempts(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.rushAttempts) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.rushAttempts;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalRushYards(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.rushYards) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.rushYards;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalRushTouchdowns(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.rushTouchdowns) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.rushTouchdowns;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalReceptions(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.receptions) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.receptions;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalReceiveYards(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.receiveYards) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.receiveYards;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalReceiveTouchdowns(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.receiveTouchdowns) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.receiveTouchdowns;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }

    getTotalFumbles(roster: Roster, category?: string) {
        let result = 0;
        if (roster) {
            for (let rs of roster.spots) {
                if (!category || this.isInCategory(rs, category)) {
                    if (rs.player?.fumbles) {
                        if (this.isSpotIncludedInTotal(rs)) {
                            result += rs.player.fumbles;
                        }
                    }
                }
            }
        }
        result = UberdraftUtil.roundTo2Digits(result);
        return result;
    }
}