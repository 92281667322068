<div class="container">

    <ng-container  *ngIf="stateService.league; else listLeagues">
        <div class="row">
            <div class="col ms-auto me-auto mt-4 text-center">
                <h1>Uberdraft</h1>
            </div>
        </div>
        <div class="row">
            <div class="col ms-auto me-auto mt-4 text-center h3">
                <a [routerLink]="['/draft']">Live Draft</a>
                | <a [routerLink]="['/draft-all']">Full Results</a>
            </div>
        </div>
        <div class="row">
            <div class="col ms-auto me-auto mt-4 text-center h3">
                <a [routerLink]="['/rosters']">Rosters</a>
            </div>
        </div>

        <ng-container *ngIf="stateService.hasAdminRights()">
            
            <div class="row">
                <div class="col ms-auto me-auto mt-4 text-center h3">
                    <a [routerLink]="['/players']">Players</a>
                </div>
            </div>

            <div class="row" *ngIf="stateService.league.sport === 'BASEBALL'">
                <div class="col ms-auto me-auto mt-4 text-center h3">
                    <a [routerLink]="['/standings']">Standings</a>
                </div>
            </div>

            <div class="row" *ngIf="stateService.league.sport === 'FOOTBALL'">
                <div class="col ms-auto me-auto mt-4 text-center h3">
                    <a [routerLink]="['/footballstandings']">Standings</a>
                </div>
            </div>

            <div class="row">
                <div class="col ms-auto me-auto mt-4 text-center h3">
                    <a [routerLink]="['/settings']">League Settings</a>
                </div>
            </div>



        </ng-container>

        <div class="row">
            <div class="col ms-auto me-auto mt-5 text-center h5">
                <a href="javascript:void(0);" (click)="clearLeague()">Change League</a>
                <ng-container *ngIf="stateService.hasAdminRights()">
                    | <a href="javascript:void(0);" (click)="logout()">Logout</a>
                    | <a [routerLink]="['/sync']">Sync</a>
                    | <a [routerLink]="['/import']">Import</a>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-template #listLeagues>
        
        <ng-container *ngIf="leagues; else loading">
            <div class="row">
                <div class="col ms-auto me-auto mt-4 text-center">
                    <h3>Select a League:</h3>
                </div>
            </div>
            <div class="row" *ngFor="let league of leagues">
                <div class="col ms-auto me-auto mt-2 text-center">
                    <a href="javascript:void(0)" (click)="selectLeague(league)" class="h5">{{league.name}}</a>
                </div>
            </div>
        </ng-container>
        
        <ng-template #loading>
            <div class="d-flex justify-content-center mt-5">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
            </div>
        </ng-template>    
    </ng-template>
</div>

