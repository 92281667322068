import * as moment from 'moment-timezone';
import { Papa } from 'ngx-papaparse';
import { PossibleValue, SelectOption, Validator } from '.';


export class PropertyFilter {

    key: string;
    name: string;
    type: string;
    matchMode: string;
    value: string;
    valueLabel: string;
    possibleValues: PossibleValue[];
    collection: boolean;
    dynamicPropertyFilterStrategy: any;
    possibleValueStrategy: any;
    customFilterStrategy: any;
    enabledFilterKey: string;
    enabledFilterValue: string;
    validators: Validator[];

    possibleValueSelectOptions: SelectOption[];
    jsonField: boolean;

    static stringEquals(key: string, value: string): PropertyFilter {
        return PropertyFilter.string(key, value, 'EQUALS');
    }

    static string(key: string, value: string, matchMode: string): PropertyFilter {
        let result = new PropertyFilter();
        result.key = key;
        result.value = value;
        result.matchMode = matchMode;
        result.type = 'STRING';
        return result;
    }

    static enumEquals(key: string, value: string): PropertyFilter {
        let result = new PropertyFilter();
        result.key = key;
        result.value = value;
        result.matchMode = 'EQUALS';
        result.type = 'ENUM';
        return result;
    }

    static booleanEquals(key: string, value: boolean): PropertyFilter {
        let result = new PropertyFilter();
        result.key = key;
        result.value = '' + value;
        result.matchMode = 'EQUALS';
        result.type = 'BOOLEAN';
        return result;
    }

    static numberEquals(key: string, value: number): PropertyFilter {
        let result = new PropertyFilter();
        result.key = key;
        result.value = '' + value;
        result.matchMode = 'EQUALS';
        result.type = 'NUMBER';
        return result;
    }

    static dateEquals(key: string, value: Date): PropertyFilter {
        return PropertyFilter.date(key, value, 'EQUALS');
    }

    static date(key: string, value: Date, matchMode: string): PropertyFilter {
        let result = new PropertyFilter();
        result.key = key;
        result.value = moment(value).toISOString();
        result.matchMode = matchMode;
        result.type = 'DATE';
        return result;
    }

    static stringList(key: string, value: string[], collectionMode: string): PropertyFilter {
        let result = new PropertyFilter();
        result.key = key;
        result.matchMode = 'EQUALS';
        result.type = 'STRING';

        let papa = new Papa();
        result.value = papa.unparse([value], {});

        return result;
    }


    static enumList(key: string, value: string[], collectionMode: string): PropertyFilter {
        let result = new PropertyFilter();
        result.key = key;
        result.matchMode = 'EQUALS';
        result.type = 'ENUM';

        let papa = new Papa();
        result.value = papa.unparse([value], {});

        return result;
    }

}
