import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { SyncService } from "../service/sync.service";

@Component({
    selector: 'sync-component',
    templateUrl: 'sync.component.html'
})
export class SyncComponent implements OnInit {
    loading: boolean = false;
    done: boolean = false;

    rostersHtml: string = null;

    constructor(private syncService: SyncService) {

    }

    ngOnInit() {
        //this.loading = true;
        // this.syncService.sync().subscribe(
        //     result => {
        //         console.log("done");
        //         this.loading = false;
        //     },
        //     error => {
        //         console.error(error);
        //     }
        // )
    }

    parse() {
        this.syncService.parseRosters(this.rostersHtml).subscribe(
            result => {
                console.log("done");
                this.loading = false;
            },
            error => {
                console.error(error);
            }        
        );
    }
}