import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as Components from './component';
import { LeagueGuard } from './guard/league.guard';
import { AdminGuard } from './guard/admin.guard';

const routes: Routes = [
  {
    path: '',
    component: Components.HomeComponent
  },
  {
    path: 'players',
    component: Components.ListPlayersComponent,
    canActivate: [LeagueGuard, AdminGuard]
  },
  {
    path: 'draft',
    component: Components.DraftComponent,
    canActivate: [LeagueGuard]
  },
  {
    path: 'draft-all',
    component: Components.DraftAllComponent,
    canActivate: [LeagueGuard]
  },
  {
    path: 'rosters',
    component: Components.RostersComponent,
    canActivate: [LeagueGuard]
  },
  {
    path: 'standings',
    component: Components.StandingsComponent,
    canActivate: [LeagueGuard, AdminGuard]
  },
  {
    path: 'footballstandings',
    component: Components.FootballStandingsComponent,
    canActivate: [LeagueGuard, AdminGuard]
  },  
  {
    path: 'analysis',
    component: Components.AnalysisComponent
  },
  {
    path: 'import',
    component: Components.ImportComponent,
    canActivate: [LeagueGuard, AdminGuard]
  },
  {
    path: 'sync',
    component: Components.SyncComponent,
    canActivate: [LeagueGuard, AdminGuard]
  },
  {
    path: 'login',
    component: Components.LoginComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
