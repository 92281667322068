<div class="container-fluid">

    <ng-container>
        <div class="row">
            <div class="col ms-auto me-auto mt-4 text-center">
                <h1>Import</h1>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12">
                <div class="card"  *ngIf="panel === 'upload'">
                    <div class="card-header">
                        <h4 class="card-title">Import Player File > File</h4>
                    </div>
                    <div class="card-content">
                        <div class="card-body">
                            <p class="card-text">Upload a CSV file of players. We will configure what it represents after you upload it.</p>
                            
                            <div class="dropzone dropzone-container dropzone-area dz-clickable" [dropzone]="config" (init)="onUploadInit($event)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)" (canceled)="onUploadCanceled($event)">
                                 <div class="dz-message">Click or Drop File Here To Upload</div>
                            </div>
    
                            <div class="mt-2">
                                <a href="javascript:void(0)" (click)="readFile()" class="btn btn-primary">Read File</a>
                                <a href="javascript:void(0)" (click)="deletePlayers()" class="text-secondary ms-4">Delete Players</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card"  *ngIf="panel === 'headers'">
                    <div class="card-header">
                        <h4 class="card-title">Import Player File > Headers</h4>
                    </div>
                    <div class="card-content">
                        <div class="card-body">
                            <p class="card-text">Help us with the format of your file.</p>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm" style='font-size: 0.8em;'>
                                    <thead>
                                        <tr>
                                            <th *ngFor="let header of headers">
                                                {{header}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of sampleData">
                                            <td *ngFor="let col of row">
                                                {{col}}
                                            </td> 

                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td *ngFor="let header of headers">
                                                <select [(ngModel)]="columnMappings[header]">
                                                    <option [ngValue]='null'>None</option>
                                                    <option *ngFor="let colType of possibleColumnTypes; index as j" [ngValue]="colType.key" [selected]="columnMappings[header] === colType.key">{{colType.label}}</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div *ngIf="!hasPositionColumn() && positions">
                                There isn't a position column in this file.  Please select the position that applies to these players:<br>
                                <select [(ngModel)]="selectedPosition">
                                    <option [ngValue]='null'>None</option>
                                    <option *ngFor="let position of positions; index as j" [ngValue]="position" [selected]="position === selectedPosition">{{position.name}}</option>
                                </select>

                            </div>
                            <div class="mt-2">
                                <a href="javascript:void(0)" (click)="process()" class="btn btn-primary">Process</a>
                                <a href='javascript:void(0)' (click)="reset()" class="text-secondary ms-4">Start over</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card"  *ngIf="panel === 'success'">
                    <div class="card-header">
                        <h4 class="card-title">Success</h4>
                    </div>
                    <div class="card-content">
                        <div class="card-body">

                            <p class="card-text">
                                {{message}}<br><br>
                                <a href='javascript:void(0)' (click)="reset()">Upload Another</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card"  *ngIf="panel === 'error'">
                    <div class="card-header">
                        <h4 class="card-title">Error</h4>
                    </div>
                    <div class="card-content">
                        <div class="card-body">
                            <p class="card-text">
                                {{message}}<br><br><a href='javascript:void(0)' (click)="reset()">Try again</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

