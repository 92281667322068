<div class="container">
    <div class="row" *ngIf="!loading && !done">
        <div class="col ms-auto me-auto mt-4 text-center">
            <textarea [(ngModel)]="rostersHtml" rows="10" class="w-100">
            </textarea>
        </div>
    </div>
    <div class="row" *ngIf="!loading && !done">
        <div class="col ms-auto me-auto mt-4 text-center">
            <button (click)="parse()">Parse</button>
        </div>
    </div>
    <div class="row" *ngIf="loading">
        <div class="col ms-auto me-auto mt-4 text-center">
           Loading...
        </div>
    </div>
    <div class="row" *ngIf="done">
        <div class="col ms-auto me-auto mt-4 text-center">
            Done...
         </div>
    </div>
</div>
